import cloneDeep from 'lodash/cloneDeep';
import type { PiniaPluginContext } from 'pinia';

type IResetSideEffects = undefined | (() => void);

export default function resetStore({ store }: PiniaPluginContext) {
  const initialState = cloneDeep(store.$state);
  const resetSideEffects: IResetSideEffects = store.$resetSideEffects;

  store.$reset = () => {
    if (typeof resetSideEffects === 'function') resetSideEffects();
    store.$patch(cloneDeep(initialState));
  };
}
