import { computed, ref, watch } from 'vue';
import { defineStore } from 'pinia';
import _flatten from 'lodash/flatten';
import {
  Feature,
  LineString,
  Polygon,
  Properties,
  bbox as turfBbox,
  polygon as turfPolygon,
  Position,
} from '@turf/turf';

import { MapboxGeoJSONFeature } from 'mapbox-gl';
import { prepareSelectionFeature } from '@/utils/selection';
import { wkb2GeoJsonStr } from '@/utils/common';

import { ISelectionFeature, SelectionUpdate, SelectionType, ISelectionLegend } from '@/types/selection';
import type { ILayer } from '@/types/agency';
import {
  DRAWING_MODES,
  ILabelProperties,
  IShapeAnnotation,
  IShape,
  IShapeSelectedVertices,
  IShapeProperties,
  SHAPE_ANNOTATION,
} from '@/types/shape';
import { castArray } from 'lodash';
import { shapeSettings } from '@/constants/shape';
import { useHistory } from '@/hooks/useHistory';
import { agencyLocationCalc, getLayerStandardFeature, getLayerFeature, getGeoJSON } from '@/services/api.service';

import useMapStore from './map';
import useGlobalStore from '@/stores/global';
import dispatchEvent from '@/utils/dispatchEvent';
import { toggleShapeSelection } from '@/utils/shapes/common';
import {
  IPolygon,
  geometryToParts,
  partsToPolygons,
  polygonsToFeatures,
  styleToProperties,
} from '@/utils/shapes/boundary';

import { FeaturelessLayers } from '@/constants/layers';

import { BaseLayersCategory } from '@/types/layers';
import { BoundaryProperties } from '@/types/map';
import { isValidGeos } from '@/utils/shapes/makeValid';
import { mergeBoundingBoxes } from '@/utils/shapes/mergeBoundingBoxes';

const useSelectionStore = defineStore('selection', () => {
  const mapStore = useMapStore();
  const globalStore = useGlobalStore();

  const features = ref<ISelectionFeature[]>([]);

  const selectionLegend = ref<ISelectionLegend[]>([]);
  const geoDetails = ref<{ area: number; perimeter: number }>({ area: 0, perimeter: 0 });

  const refreshShapeSelection = ref<number>(0);
  const drawMode = ref<DRAWING_MODES>(DRAWING_MODES.simpleSelect);
  const isSelectPointOnTheMapMode = ref<boolean>(false);

  const carveOutShape = ref<null | IShape>(null);
  const shapeSelectedVertices = ref<null | IShapeSelectedVertices>(null);
  // The "Marker" shape is created too quickly and we are unable to detect draw_point mode with drawControl.getMode()
  // So we are using this flag instead
  const shapePointCreated = ref<boolean>(false);

  const {
    current: shapes,
    hasRedo: hasShapesRedo,
    hasUndo: hasShapesUndo,
    redoOperation: shapesRedo,
    undoOperation: shapesUndo,
    historyOperation: shapeOperation,
    historyOverride: shapesOverride,
    clearHistory: clearShapesHistory,
  } = useHistory<IShape[], IShapeAnnotation>([]);

  const validShapes = computed(() => {
    return shapes.value.filter(shape => isValidGeos(shape));
  });

  const queryableShapes = computed(() => {
    return validShapes.value.filter(shape => !shape.properties.muted);
  });

  watch(
    () => globalStore.study,
    () => {
      fetchLegend([], []);
    },
  );

  async function fetchLegend(features: Array<{ featureId: string; layerId: string }>, shapes: Array<string>) {
    const res = await agencyLocationCalc(features, shapes);
    selectionLegend.value = res.locationList;
    geoDetails.value.area = res.area;
    geoDetails.value.perimeter = res.perimeter;
    console.log(res);

    dispatchEvent('attention');
  }

  function addShape(shape: IShape) {
    const annotation = { type: SHAPE_ANNOTATION.ADD_SHAPE, replaceable: false };
    shapeOperation(data => [...data, shape], annotation);
  }

  function removeShape(shapeId: string) {
    const annotation = { type: SHAPE_ANNOTATION.REMOVE_SHAPE, replaceable: false };
    shapeOperation(data => data.filter(v => v.id !== shapeId), annotation);
  }

  function removeAllShapes() {
    const annotation = { type: SHAPE_ANNOTATION.REMOVE_ALL_SHAPES, replaceable: false };
    shapeOperation(() => [], annotation);
  }

  function bulkUpdateShapes({ created = [], deleted = [] }: { created?: IShape[]; deleted?: string[] }) {
    const annotation = { type: SHAPE_ANNOTATION.BULK_UPDATE, replaceable: false };
    shapeOperation(data => [...data.filter(v => !deleted.includes(v.id)), ...created], annotation);
  }

  function updateShapesGeometry(shapeOrShapes: IShape | IShape[]) {
    const annotation = { type: SHAPE_ANNOTATION.UPDATE_GEOMETRY, replaceable: false };
    const shapes = castArray(shapeOrShapes);
    const shapesValidity = shapes.map(shape => isValidGeos(shape));

    shapeOperation(
      data =>
        data.map(shape => {
          const index = shapes.findIndex(
            s => s.id === shape.id || (shape.usershapeid && s.usershapeid === shape.usershapeid),
          );
          return index !== -1
            ? {
                ...shape,
                geometry: { ...shape.geometry, ...shapes[index].geometry },
                properties: { ...shape.properties, invalid: !shapesValidity[index] },
                labelProperties: { ...shape.labelProperties, invalid: !shapesValidity[index] },
                settings: shapeSettings,
              }
            : shape;
        }),
      annotation,
    );
  }

  function updateShapeSettings({
    shapeId,
    shapeData,
    annotation,
  }: {
    shapeId: string;
    shapeData: Partial<IShape>;
    annotation: IShapeAnnotation;
  }) {
    shapeOperation(data => {
      return data.map(shape => (shape.id === shapeId ? { ...shape, ...shapeData } : shape));
    }, annotation);
  }

  function updateShapeMuted({ shapeId, muted }: { shapeId: string; muted: boolean }) {
    const annotation = { type: SHAPE_ANNOTATION.UPDATE_MUTED, replaceable: false };
    shapeOperation(data => {
      return data.map(shape =>
        shape.id === shapeId
          ? {
              ...shape,
              properties: { ...shape.properties, muted },
              labelProperties: { ...shape.labelProperties, muted },
            }
          : shape,
      );
    }, annotation);
  }

  function updateUserShapeId({ shapeId, userShapeId }: { shapeId: string; userShapeId: number | undefined }) {
    shapesOverride(data => {
      return data.map(shape => (shape.id === shapeId ? { ...shape, usershapeid: userShapeId } : shape));
    });
  }

  function updateShapeProperties({
    shapeId,
    properties,
    annotation,
  }: {
    shapeId: string;
    properties: Partial<IShapeProperties>;
    annotation: IShapeAnnotation;
  }) {
    shapeOperation(data => {
      return data.map(shape =>
        shape.id === shapeId ? { ...shape, properties: { ...shape.properties, ...properties } } : shape,
      );
    }, annotation);
  }

  function updateShapeLabelProperties({
    shapeId,
    properties,
    annotation,
  }: {
    shapeId: string;
    properties: Partial<ILabelProperties>;
    annotation: IShapeAnnotation;
  }) {
    shapeOperation(data => {
      return data.map(shape =>
        shape.id === shapeId ? { ...shape, labelProperties: { ...shape.labelProperties, ...properties } } : shape,
      );
    }, annotation);
  }

  function setRefreshSelection() {
    refreshShapeSelection.value++;
  }

  function setSelectedVertices(vertices: IShapeSelectedVertices | null) {
    shapeSelectedVertices.value = vertices;
  }

  function setCarveOutShape(shape: IShape | null) {
    carveOutShape.value = shape;
  }

  function setDrawMode(mode: DRAWING_MODES) {
    // Clear carve-out shape and selected vertices on mode change
    if (mode !== DRAWING_MODES.selectPoints && shapeSelectedVertices.value !== null) {
      setSelectedVertices(null);
    }
    if (mode !== DRAWING_MODES.carveOut && carveOutShape.value !== null) {
      setCarveOutShape(null);
    }

    drawMode.value = mode;
  }

  function updateSelection({
    feature,
    layer,
    type,
  }: {
    feature: MapboxGeoJSONFeature;
    layer: ILayer;
    type: SelectionUpdate;
  }) {
    switch (type) {
      case SelectionUpdate.Replace:
        features.value = [prepareSelectionFeature({ feature, layer, type: SelectionType.Selection })];
        break;

      default:
        toggleSelection(feature, layer);
    }
  }

  function toggleSelection(feature: MapboxGeoJSONFeature, layer: ILayer) {
    const isExist = !!features.value.find(
      ({ id, layer: featureLayer }) => id === feature.id && layer.id === featureLayer.id,
    );

    if (!isExist) {
      features.value.push(prepareSelectionFeature({ feature, layer, type: SelectionType.Selection }));
    } else {
      features.value = features.value.filter(v => v.id !== feature.id);
    }
  }

  function clearSelection() {
    features.value = [];
  }

  function removeSelection(featureId: number, layerId: string) {
    features.value = features.value.filter(v => v.id !== featureId || v.layer.id !== layerId);
  }

  function removeSourceLayerSelection(featureId: number | string, layerId: string) {
    features.value = features.value.filter(v => String(v.id) !== String(featureId) || v.layer.source.layer !== layerId);
  }

  function setShapePointCreated(created: boolean) {
    shapePointCreated.value = created;
  }

  function setSelectPointOnTheMapMode(select: boolean) {
    isSelectPointOnTheMapMode.value = select;
  }

  function $resetSideEffects() {
    clearShapesHistory();
  }

  function toggleFeatureShapeSelection({ shapeId, isSelected = false }: { shapeId: string; isSelected?: boolean }) {
    if (mapStore.drawControl) {
      toggleShapeSelection({
        drawControl: mapStore.drawControl,
        shapeSelected: isSelected,
        shapeId,
      });

      setRefreshSelection();
    }
  }

  type BBox2d = [number, number, number, number];

  function fitMap(bbox: BBox2d | null, options?: mapboxgl.FitBoundsOptions) {
    if (!bbox) {
      return;
    }

    mapStore?.map?.fitBounds(bbox, { padding: 20, ...options });
  }

  interface IBoundaryFeatureRequestOptions {
    select?: boolean;
    zoom?: boolean;
    draw?: boolean;
    zoomOptions?: mapboxgl.FitBoundsOptions;
  }
  interface IBoundaryFeatureRequest {
    layerId: string;
    featureId: string;
    featureName: string;
    categoryName: string;
  }

  async function fetchBoundaryFeatures(items: IBoundaryFeatureRequest[], options?: IBoundaryFeatureRequestOptions) {
    const { zoom = true, zoomOptions, draw = true, select } = options || {};

    const result = await Promise.allSettled(
      items.map(item => fetchBoundaryFeature(item, { select, draw, zoom, zoomOptions })),
    );

    if (!zoom) {
      return;
    }

    const featuresBboxes = result.filter(item => item.status === 'fulfilled').map((item: any) => item?.value);
    const shapesBboxes = queryableShapes.value.map(shape => turfBbox(shape));

    if (featuresBboxes.length || shapesBboxes.length) {
      const generalBbox = mergeBoundingBoxes([...featuresBboxes, ...shapesBboxes]);

      fitMap(generalBbox, zoomOptions);
    }
  }

  interface ICreateFeaturesShapesOptions extends IBoundaryFeatureRequestOptions {
    featureId?: string;
    layerId?: string;
  }

  const createFeaturesShapes = (shapes: any[], options?: ICreateFeaturesShapesOptions) => {
    const { select, featureId, layerId } = options || {};

    mapStore.map?.fire('draw.create', { features: shapes });

    shapes.forEach(shape => {
      mapStore.drawControl?.add(shape);

      if (select && featureId && layerId) {
        toggleFeatureShapeSelection({ shapeId: shape.id });
        removeSourceLayerSelection(featureId, layerId);
      }
    });
  };

  const getPolygonsBbox = (polygons: IPolygon[]) => {
    const polygonBboxes = polygons.map(polygon => {
      const poly = turfPolygon(polygon.geometry.coordinates as Position[][]);

      return turfBbox(poly);
    });

    return mergeBoundingBoxes(polygonBboxes);
  };

  type FeaturelessPart = Feature<Polygon | LineString, Properties>[];

  type GeometryFeature = { geometry: GeoJSON.Geometry; properties: BoundaryProperties };

  async function fetchFeaturelessLayer(
    { layerId, featureId, featureName }: { layerId: string; featureId: string; featureName: string },
    options?: IBoundaryFeatureRequestOptions,
  ) {
    if (!globalStore.study) return;

    const { draw } = options || {};

    const response = await getGeoJSON(globalStore.study.agency.uuid, layerId);
    const geometry = JSON.parse(response.getGeojson());

    const parts: FeaturelessPart[] = geometry.features.map((feature: GeometryFeature) =>
      geometryToParts(feature.geometry, feature.properties),
    );

    const flatParts = _flatten(parts);
    const polygons = partsToPolygons(flatParts);
    const shapes = polygonsToFeatures(polygons, featureName);

    if (draw) {
      createFeaturesShapes(shapes, { layerId, featureId, ...options });
    }

    return getPolygonsBbox(polygons);
  }

  async function fetchBoundaryFeature(
    { layerId, featureId, featureName, categoryName }: IBoundaryFeatureRequest,
    options?: IBoundaryFeatureRequestOptions,
  ) {
    const { select, draw = true } = options || {};

    const isStandartLayer = categoryName === BaseLayersCategory.STANDART_LAYERS;
    const isFeaturelessLayer = FeaturelessLayers.includes(categoryName);

    if (isFeaturelessLayer) {
      return await fetchFeaturelessLayer({ layerId, featureId, featureName }, options);
    }

    const request = isStandartLayer ? getLayerStandardFeature : getLayerFeature;

    const layerFeature = await request(layerId, featureId);

    const properties = styleToProperties({ ...layerFeature.style1, name: layerFeature.name });
    const geometry = wkb2GeoJsonStr(layerFeature.geography);
    const parts = geometryToParts(geometry, properties);
    const polygons = partsToPolygons(parts);
    const shapes = polygonsToFeatures(polygons, featureName);

    if (draw) {
      createFeaturesShapes(shapes, { select, layerId, featureId });
    }

    return getPolygonsBbox(polygons);
  }

  return {
    features,
    shapes,
    validShapes,
    queryableShapes,
    selectionLegend,
    refreshShapeSelection,
    shapeSelectedVertices,
    carveOutShape,
    shapePointCreated,
    isSelectPointOnTheMapMode,
    drawMode,

    geoDetails,

    hasShapesUndo,
    hasShapesRedo,

    $resetSideEffects,
    fetchLegend,

    updateSelection,
    toggleSelection,
    clearSelection,
    removeSelection,

    clearShapesHistory,
    shapeOperation,
    shapesRedo,
    shapesUndo,

    addShape,
    removeShape,
    removeAllShapes,
    bulkUpdateShapes,
    updateShapesGeometry,

    updateShapeMuted,
    updateShapeProperties,
    updateShapeLabelProperties,
    updateShapeSettings,
    updateUserShapeId,

    setDrawMode,
    setRefreshSelection,
    setSelectedVertices,
    setCarveOutShape,
    setShapePointCreated,
    setSelectPointOnTheMapMode,

    fetchBoundaryFeatures,
  };
});

export default useSelectionStore;
