import { DRAW_SHAPE_DATA_TYPE, DRAWING_MODES, SHAPE } from '@/types/shape';

import type { IAdvancedShapeSettings, ILabelProperties, IShapeProperties } from '@/types/shape';
import type { BoundaryProperties, TMapDrawEvent } from '@/types/map';

export const shapeDataTypeLabelMap: Record<string, string> = {
  [DRAW_SHAPE_DATA_TYPE.WKT]: 'Well Known Text',
  [DRAW_SHAPE_DATA_TYPE.GEOJSON]: 'GeoJSON',
};

export const shapeTypeAndNameMap: Record<string, string> = {
  [SHAPE.Polygon]: 'Polygon',
  [SHAPE.LineDistance]: 'Line',
  [SHAPE.Point]: 'Point',
};

export const shapeNameTypeMap: Record<string, string> = {
  [DRAWING_MODES.polygon]: 'Polygon',
  [DRAWING_MODES.rectangle]: 'Rectangle',
  [DRAWING_MODES.circle]: 'Radius',
  [DRAWING_MODES.lineDistance]: 'Line',
  [DRAWING_MODES.freeHand]: 'Polygon',
  [DRAWING_MODES.point]: 'Marker',
};

export const shapesAllowedToUnion = [SHAPE.Polygon, SHAPE.Circle, SHAPE.Rectangle];

export const drawEvents: TMapDrawEvent[] = [
  'draw.create',
  'draw.delete',
  'draw.combine',
  'draw.uncombine',
  'draw.update',
  'draw.selectionchange',
  'draw.modechange',
  'draw.render',
  'draw.actionable',
  'draw.select-points',
  'draw.carve-out',
];

export const shapeLabelLayerIdPrefix = 'gl-draw-shape-label';

export const shapeSettings: IAdvancedShapeSettings = {
  simplify: 0,
  buffer: 0,
  rotate: 0,
  scale: 100,
};

export const defaultShapeLabelProperties: ILabelProperties = {
  name: '',
  showLabel: true,
  muted: false,
  invalid: false,

  font: 'Arial',
  fontSize: 18,
  fontColor: '#000000',
  fontOpacity: 1,

  haloColor: '#FFFFFF',
  haloOpacity: 1,
  position: [0, 0],
};

export const defaultShapeProperties: IShapeProperties = {
  invalid: false,
  nonRepairable: false,
  muted: false,

  fillColor: '#0033cc',
  fillOpacity: 0.1,

  strokeColor: '#0033cc',
  strokeOpacity: 1,
  strokeWidth: 4,
  type: SHAPE.Polygon,
};

const defaultColor = '#0033cc';
const defaultDisabledColor = '#a3a3a3';
const notMutedAndValid = [
  'all',
  ['==', ['boolean', ['get', 'user_muted'], false], false],
  ['==', ['boolean', ['get', 'user_invalid'], false], false],
];
const mutedOrInvalid = [
  'any',
  ['==', ['boolean', ['get', 'user_muted'], false], true],
  ['==', ['boolean', ['get', 'user_invalid'], false], true],
];
const fillColor = ['string', ['get', 'user_fillColor'], defaultColor];
const strokeColor = ['string', ['get', 'user_strokeColor'], defaultColor];
const fillOpacity = ['number', ['get', 'user_fillOpacity'], 0.1];
const strokeOpacity = ['number', ['get', 'user_strokeOpacity'], 1];
const strokeWidth = ['number', ['get', 'user_strokeWidth'], 4];

export const defaultDrawStyle = [
  {
    id: 'gl-draw-polygon-fill-inactive',
    type: 'fill',
    filter: ['all', ['==', 'active', 'false'], ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
    paint: {
      'fill-color': ['case', notMutedAndValid, fillColor, mutedOrInvalid, defaultDisabledColor, defaultColor],
      'fill-outline-color': ['case', notMutedAndValid, strokeColor, mutedOrInvalid, defaultDisabledColor, defaultColor],
      'fill-opacity': ['case', notMutedAndValid, fillOpacity, mutedOrInvalid, 0.1, 0.1],
    },
  },
  {
    id: 'gl-draw-polygon-fill-active',
    type: 'fill',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': ['case', notMutedAndValid, fillColor, mutedOrInvalid, defaultDisabledColor, defaultColor],
      'fill-outline-color': ['case', notMutedAndValid, strokeColor, mutedOrInvalid, defaultDisabledColor, defaultColor],
      'fill-opacity': ['case', notMutedAndValid, fillOpacity, mutedOrInvalid, 0.1, 0.1],
    },
  },
  {
    id: 'gl-draw-polygon-midpoint',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
    paint: {
      'circle-radius': 5,
      'circle-color': strokeColor,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-inactive',
    type: 'line',
    filter: ['all', ['==', 'active', 'false'], ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': ['case', notMutedAndValid, strokeColor, mutedOrInvalid, defaultDisabledColor, defaultColor],
      'line-width': strokeWidth,
      'line-opacity': ['case', notMutedAndValid, strokeOpacity, mutedOrInvalid, 0.2, 1],
    },
  },
  {
    id: 'gl-draw-polygon-stroke-active',
    type: 'line',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': ['case', notMutedAndValid, strokeColor, mutedOrInvalid, defaultDisabledColor, defaultColor],
      'line-dasharray': [0.2, 2],
      'line-width': 4,
      'line-opacity': ['case', notMutedAndValid, strokeOpacity, mutedOrInvalid, 0.2, 1],
    },
  },
  {
    id: 'gl-draw-line-inactive',
    type: 'line',
    filter: ['all', ['==', 'active', 'false'], ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': ['case', notMutedAndValid, strokeColor, mutedOrInvalid, defaultDisabledColor, defaultColor],
      'line-width': strokeWidth,
      'line-opacity': ['case', notMutedAndValid, strokeOpacity, mutedOrInvalid, 0.2, 1],
    },
  },
  {
    id: 'gl-draw-line-active',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString'], ['==', 'active', 'true']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': ['case', notMutedAndValid, strokeColor, mutedOrInvalid, defaultDisabledColor, defaultColor],
      'line-dasharray': [0.2, 2],
      'line-width': strokeWidth,
      'line-opacity': ['case', notMutedAndValid, strokeOpacity, mutedOrInvalid, 0.2, 1],
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
    type: 'circle',
    filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
    paint: {
      'circle-radius': 5,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-inactive',
    type: 'circle',
    filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
    paint: {
      'circle-radius': 3,
      'circle-color': defaultColor,
    },
  },
  {
    id: 'gl-draw-point-point-stroke-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-opacity': 1,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-point-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 3,
      'circle-color': '#3bb2d0',
    },
  },
  {
    id: 'gl-draw-point-stroke-active',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'active', 'true'], ['!=', 'meta', 'midpoint']],
    paint: {
      'circle-radius': 7,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-point-active',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['!=', 'meta', 'midpoint'], ['==', 'active', 'true']],
    paint: {
      'circle-radius': 5,
      'circle-color': '#fbb03b',
    },
  },
  {
    id: 'gl-draw-polygon-fill-static',
    type: 'fill',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': defaultColor,
      'fill-outline-color': defaultColor,
      'fill-opacity': 0.1,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-static',
    type: 'line',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': defaultColor,
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-line-static',
    type: 'line',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'LineString']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': defaultColor,
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-point-static',
    type: 'circle',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point']],
    paint: {
      'circle-radius': 5,
      'circle-color': defaultColor,
    },
  },
];

export const defaultBoundaryProperties: BoundaryProperties = {
  name: '',
  showLabel: false,
  changed: false,
  muted: true,

  fillcolor: '#000000',
  fillopacity: 20,

  strokecolor: '#000000',
  strokeopacity: 100,
  strokewidth: 2,
};
