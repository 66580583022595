export enum AbortControllerKey {
  NeighborsLegend = 'NeighborsLegend',
  AgencyLocation = 'AgencyLocation',
  PeoplePlotLegend = 'PeoplePlotLegend',
}

class AbortControllerStorage {
  private storage: Map<AbortControllerKey, AbortController> = new Map();

  constructor() {}

  public register(key: AbortControllerKey, controller: AbortController): AbortController {
    this.storage.set(key, controller);
    return this.get(key) as AbortController;
  }

  public get(key: AbortControllerKey): AbortController | undefined {
    return this.storage.get(key);
  }

  public abort(key: AbortControllerKey, reason?: string) {
    if (!this.storage.has(key)) return;

    const controller = this.storage.get(key) as AbortController;
    controller.abort(reason);

    this.storage.delete(key);
  }
}

export default new AbortControllerStorage();
