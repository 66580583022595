export enum BaseStandartLayer {
  CITIES = 'cities',
  COUNTIES = 'counties',
  ZIP_CODES = 'zip',
  STATES = 'states',
}

export enum BaseLayersCategory {
  STANDART_LAYERS = 'Standard layers',
  AGENCY_BOUNDARIES = 'Agency Boundaries',
  AGENCY_LOCATIONS = 'Agency Locations',
}

export const AgencyBoundaryLayer = 'Agency Boundary';
export const AgencyBoundaryLayerId = 'boundaries';
