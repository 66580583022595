export const contacts = {
  email: 'misupport@acst.com',
  phone: {
    main: '877.230.3212',
    entries: [
      { tel: '843-242-7633', name: 'Support' },
      { tel: '877-230-3212', name: 'Support (Toll-free)' },
      { tel: '843-252-0412', name: 'Sales' },
    ],
  },
};
export const registrationHandout = 'https://acst-mi-docs.s3.amazonaws.com/Register_for_MissionInsite.pdf';

export const acstSrc = 'https://www.acstechnologies.com/';
