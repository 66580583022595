import { v4 as uuidv4 } from 'uuid';

import { defaultBoundaryProperties, defaultShapeLabelProperties, defaultShapeProperties } from '@/constants/shape';

import { type Feature, type Polygon, type LineString, feature } from '@turf/turf';
import type { BoundaryProperties } from '@/types/map';

export function styleToProperties(properties: BoundaryProperties): BoundaryProperties {
  const fillcolor = properties.fillcolor || defaultBoundaryProperties.fillcolor;
  const strokecolor = properties.strokecolor || defaultBoundaryProperties.strokecolor;
  const fillopacity = (properties.fillopacity ?? defaultBoundaryProperties.fillopacity) / 100;
  const strokeopacity = (properties.strokeopacity ?? defaultBoundaryProperties.strokeopacity) / 100;

  return { ...defaultBoundaryProperties, ...properties, fillcolor, strokecolor, fillopacity, strokeopacity };
}

export function geometryToParts(
  geometry: GeoJSON.Geometry,
  properties: BoundaryProperties,
): Array<Feature<Polygon | LineString>> {
  if (geometry.type === 'Polygon' || geometry.type === 'LineString') {
    return [feature(geometry, properties, { id: uuidv4() })];
  }

  if (geometry.type === 'MultiPolygon') {
    return geometry.coordinates.flatMap(coordinates => {
      return coordinates.map(coord => feature({ type: 'Polygon', coordinates: [coord] }, properties, { id: uuidv4() }));
    });
  }

  if (geometry.type === 'GeometryCollection') {
    return geometry.geometries.flatMap(geometry => geometryToParts(geometry, properties));
  }

  return [];
}

export interface IPolygon {
  geometry: Polygon | LineString;
  type: string;
}

export function partsToPolygons(parts: Array<Feature<Polygon | LineString>>): IPolygon[] {
  return parts.map(part => ({
    geometry: part.geometry,
    type: 'Feature',
  }));
}

export function polygonsToFeatures(polygons: IPolygon[], name?: string): any[] {
  return polygons.map(polygon => ({
    id: uuidv4(),
    ...polygon,
    properties: defaultShapeProperties,
    labelProperties: {
      ...defaultShapeLabelProperties,
      name: name || 'Polygon',
      position: [0, 0],
    },
    settings: {},
  }));
}
