import { isValueEmpty } from '@/utils/common';
import { apiUrls, publicUrls } from '@/constants/urls';
import { ERROR_MESSAGES } from '@/constants/errors';
import request from './request.service';
import UrlRestoringService from './urlRestoring.service';
import { ILoginFunctionProps, IRegisterFunctionProps } from '@/types/request';
import { User } from '@/stores/auth';
import { event } from 'vue-gtag';
import { getStudyToken, getUserFromToken, userImpersonationLogin } from './api.service';

const ACCESS_TOKEN_KEY = 'accessToken';
const REFRESH_TOKEN_KEY = 'refreshToken';
const IS_IMPERSONATED = 'isImpersonated';

const parse = JSON.parse;
const stringify = JSON.stringify;
let accessTokenPromise: Promise<string | void> = Promise.resolve();
const auth = {
  /**
   * Remove an item from the used storage
   * @param  {String} key [description]
   */
  clear(key: string) {
    if (localStorage && localStorage.getItem(key)) {
      return localStorage.removeItem(key);
    }

    if (sessionStorage && sessionStorage.getItem(key)) {
      return sessionStorage.removeItem(key);
    }

    return null;
  },

  /**
   * Clear all app storage
   */
  clearAppStorage() {
    if (localStorage) {
      localStorage.clear();
    }

    if (sessionStorage) {
      sessionStorage.clear();
    }
  },

  clearTokens() {
    auth.clear(ACCESS_TOKEN_KEY);
    auth.clear(REFRESH_TOKEN_KEY);
  },

  clearIsImpersonated() {
    auth.clear(IS_IMPERSONATED);
  },

  async getStudyToken(studyUuid: string) {
    const promise = getStudyToken(studyUuid).then((token: any) => {
      if (token.jwttoken) {
        this.setAccessToken(token.jwttoken);
      }
    });

    accessTokenPromise = promise.then(() => auth.get(ACCESS_TOKEN_KEY));
    return promise;
  },

  async impersonateUser(userUuid: string) {
    const promise = userImpersonationLogin(userUuid).then(token => {
      if (token?.jwttoken) {
        this.setAccessToken(token.jwttoken);
        this.setIsImpersonated('true');
      }
    });
    accessTokenPromise = promise.then(() => auth.get(ACCESS_TOKEN_KEY));
    return promise;
  },

  /**
   * Returns data from storage
   * @param  {String} key Item to get from the storage
   * @return {String|Object}     Data from the storage
   */
  get(key: string) {
    const localStorageItem = localStorage.getItem(key);
    const sessionStorageItem = sessionStorage.getItem(key);

    if (localStorage && localStorageItem !== null) {
      return parse(localStorageItem) || null;
    }

    if (sessionStorage && sessionStorageItem) {
      return parse(sessionStorageItem) || null;
    }

    return null;
  },
  /**
   * Set data in storage
   * @param {String|Object}  value    The data to store
   * @param {String}  key
   * @param {Boolean} isLocalStorage  Defines if we need to store in localStorage or sessionStorage
   */
  set(value: string | object, key: string, isLocalStorage: boolean) {
    if (isValueEmpty(value)) {
      return null;
    }
    if (isLocalStorage && localStorage) {
      return localStorage.setItem(key, stringify(value));
    }

    if (sessionStorage) {
      return sessionStorage.setItem(key, stringify(value));
    }

    return null;
  },

  getIsImpesonated() {
    return auth.get(IS_IMPERSONATED) === 'true';
  },

  getAccessToken(): Promise<string | void> {
    return accessTokenPromise;
  },

  getRefreshToken() {
    return auth.get(REFRESH_TOKEN_KEY);
  },

  setRefreshToken(value = '', isLocalStorage = true) {
    return auth.set(value, REFRESH_TOKEN_KEY, isLocalStorage);
  },

  setAccessToken(value = '', isLocalStorage = true) {
    auth.set(value, ACCESS_TOKEN_KEY, isLocalStorage);
    accessTokenPromise = accessTokenPromise.then(() => auth.get(ACCESS_TOKEN_KEY));
  },

  setIsImpersonated(value = '', isLocalStorage = true) {
    return auth.set(value, IS_IMPERSONATED, isLocalStorage);
  },

  async login({ username = null, password = null }: ILoginFunctionProps): Promise<void> {
    if (!username || !password) {
      throw new Error(ERROR_MESSAGES.AUTH.EMPTY_DATA);
    }
    auth.clearTokens();

    event('login', { message: `Login attempt: ${username}` });

    const result = await request(apiUrls.auth.login, {
      method: 'POST',
      body: {
        username,
        password,
      },
    }).then(response => response.json());

    auth.setAccessToken(result.accessToken);
    auth.setRefreshToken(result.refreshToken);

    window.location.href = UrlRestoringService.getUrl();
  },

  async register({ username = null, password = null }: IRegisterFunctionProps) {
    if (!username || !password) {
      throw new Error(ERROR_MESSAGES.AUTH.EMPTY_DATA);
    }
    auth.clearTokens();

    event('sign_up', { message: `Sign up attempt: ${username}` });

    const result = await request(apiUrls.auth.login, {
      method: 'POST',
      body: {
        username,
        password,
      },
    }).then(response => response.json());

    auth.setAccessToken(result.accessToken);
    auth.setRefreshToken(result.refreshToken);
    window.location.href = UrlRestoringService.getUrl();
  },

  async getCurrentUser(): Promise<User | null> {
    try {
      return await getUserFromToken();
      /*
      return {
        ...out,
        aclsList: [
          'iabv',
          'mapp',
          'male',
          'maue',
          'maui',
          'mapu',
          'aaa',
          'iajv',
          'iajm',
          'asa',
          'malm',
          'idtm',
          'iabm',
          'madu',
          'madm',
          'manc',
          'masa',
          'matm',
          'iasv',
          // 'maum',
          'maos',
          'iasm',
          'marr',
          'iaam',
          'mala',
          'iaav',
          'maam',
        ],
      };
      */
    } catch (err: unknown) {
      event('error', { message: err });
      return null;
    }
  },

  async logout(redirect = true) {
    auth.clearTokens();
    auth.clearIsImpersonated();

    if (redirect) window.location.href = publicUrls.startLogout;
  },

  noAgencyRedirect() {
    window.location.href = publicUrls.noagency;
  },
};
accessTokenPromise = Promise.resolve(auth.get(ACCESS_TOKEN_KEY));
export default auth;
