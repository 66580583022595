import tinycolor from 'tinycolor2';
import { Buffer } from 'buffer';
// @ts-ignore
window.Buffer = Buffer;

import wkx from 'wkx';

// @ts-ignore
import * as acst from '@acst/missioninsite-apiservice';

export function isValueEmpty(value: unknown): boolean {
  if (value === undefined || value === null) {
    return true;
  }

  if (value instanceof Date) {
    return false;
  }

  if (typeof value === 'string') {
    return !value;
  }

  if (typeof value === 'object' && Array.isArray(value)) {
    return !value.length;
  }

  if (typeof value === 'object') {
    return !Object.keys(value).length;
  }

  return false;
}
export function formatDate(time: number) {
  if (!+time) return '-';
  const date = new Date(time);
  return new Intl.DateTimeFormat('en-US', {
    dateStyle: 'short',
    timeStyle: 'short',
  }).format(date);
}

export function formatNumber(value: number) {
  return new Intl.NumberFormat('en-US', {}).format(value);
}

import { event } from 'vue-gtag';

export function parseJson(value: unknown): object {
  if (typeof value !== 'string') {
    return {};
  }

  try {
    return JSON.parse(value);
  } catch (err: unknown) {
    event('error', { message: err });
    return {};
  }
}

export const phone = (value: string) => value.replaceAll('.', '').replaceAll('-', '');

export const clone = (obj: object) => JSON.parse(JSON.stringify(obj));

export function hexColorForString(str: string): string {
  function hashCode(str: string): number {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  function intToRgb(int: number) {
    const c = (int & 0x00ffffff).toString(16).toUpperCase();
    return '00000'.substring(0, 6 - c.length) + c;
  }

  const color = tinycolor(intToRgb(hashCode(str)));
  return color.getBrightness() <= 45 ? color.brighten(40).toHexString() : color.toHexString();
}

export const convertGeoJSON: (apiResponse: acst.LayerGeoJsonResponse) => GeoJSON.FeatureCollection = (
  apiResponse: acst.LayerGeoJsonResponse,
) => {
  const result: GeoJSON.FeatureCollection = {
    type: 'FeatureCollection',
    features: [],
  };
  const list = apiResponse.getFeaturesList();
  result.features = list.map((feature: acst.LayerGeoJsonResponse.Feature) => {
    if (!feature.getGeography_asU8().length) {
      return null;
    }
    return {
      type: 'Feature',
      id: parseInt(feature.getId(), 10),
      geometry: wkb2GeoJson(Buffer.from(feature.getGeography_asU8())),
      properties: parseProperties(feature.getPropertiesList()),
    };
  });
  result.features = result.features.filter(v => !!v);
  return result;
};

const parseProperties = (properties: Array<acst.LayerGeoJsonResponse.Property>) => {
  const result: Record<string, any> = {};
  properties.forEach((property: acst.LayerGeoJsonResponse.Property) => {
    const name = property.getKey();
    const type = property.getValueCase();
    let value;
    switch (type) {
      case acst.LayerGeoJsonResponse.Property.ValueCase.STRINGV:
        value = property.getStringv();
        break;
      case acst.LayerGeoJsonResponse.Property.ValueCase.INTV:
        value = property.getIntv();
        break;
      case acst.LayerGeoJsonResponse.Property.ValueCase.FLOATV:
        value = property.getFloatv();
        break;
    }
    result[name] = value;
  });
  return result;
};

export const wkb2GeoJson: (wkb: Buffer) => GeoJSON.Geometry = (wkb: Buffer) => {
  return wkx.Geometry.parse(wkb).toGeoJSON() as GeoJSON.Geometry;
};

export const wkb2GeoJsonStr: (wkbBase64: string) => GeoJSON.Geometry = (wkbBase64: string) =>
  wkx.Geometry.parse(Buffer.from(wkbBase64, 'base64')).toGeoJSON() as GeoJSON.Geometry;

export const stringSort =
  <T extends object>(key: keyof T) =>
  (a: T, b: T) => {
    const keyA = (a[key] as string).toUpperCase();
    const keyB = (b[key] as string).toUpperCase();

    if (keyA < keyB) {
      return -1;
    }
    if (keyA > keyB) {
      return 1;
    }
    return 0;
  };

export function dataURLToBase64(dataURL: string) {
  const regex = /data:(?<media_type>[^;]+);base64,(?<base64_data>.+)/;
  const match = dataURL.match(regex);

  return match?.groups ? match.groups.base64_data : undefined;
}
export const parseReadableStreamToJson = async (data: any) =>
  JSON.parse(String.fromCharCode(...(await data.getReader().read()).value));
