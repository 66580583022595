import type { ISource } from '@/types/map';
import { getLayerGeoJSON } from './api.service';
import { convertGeoJSON } from '@/utils/common';

const promisesMap: Record<string, Promise<any>> = {};
export const getSourceData = async (source: ISource): Promise<any> => {
  if (source.type !== 'geojson') {
    return;
  }
  let promise = promisesMap[source.id];
  if (!promise) {
    const { agencyUuid, layer } = source.metadata;
    promise = getLayerGeoJSON(agencyUuid, layer).then(response => convertGeoJSON(response));
    promisesMap[source.id] = promise;
  }
  return promise;
};
