import { dashboardUrls } from '@/constants/urls';
import useAuthStore from '@/stores/auth';
import { RouteLocationNormalized } from 'vue-router';

const publicGuard = async (to: RouteLocationNormalized /*, from: RouteLocationNormalized */) => {
  const store = useAuthStore();
  const authRequestWasSent = () => store.pending || store.finished;
  const userIsAuthenticated = () => store.finished && store.user !== null;

  const ensureAuthRequestWasSent = async () => {
    if (!authRequestWasSent()) {
      await store.getCurrentUser();
    }
  };

  await ensureAuthRequestWasSent();

  if (userIsAuthenticated() && !to.fullPath.includes('/registration?token=')) {
    return dashboardUrls.map;
  }
};
export default publicGuard;
