<template>
  <LayoutWithCopyright>
    <div class="loader-wrapper" v-if="router.currentRoute.value.query?.agencyCode">
      <AppLoading />
    </div>

    <template v-else>
      <div class="bg-primary d-flex items-center pl-6 pt-2 pb-2">
        <div class="container">
          <RouterLink to="/login">
            <img :src="image" alt="" style="max-width: 360px" />
          </RouterLink>
        </div>
      </div>
      <div class="px-4 pt-4 pb-10" style="max-height: calc(100vh - 116px); overflow: auto">
        <div class="container">
          <v-card class="bg-grey-lighten-3 mb-8">
            <v-card-text class="px-10 py-10">
              <h3 class="text-h5">MissionInsite Registration</h3>
            </v-card-text>
          </v-card>

          <div v-if="form.step === '3'">
            <template v-if="!form.agencyMagicCode?.length">
              <p class="mt-4 mb-4">
                Instructions: Your agency has been provided an account number. Please type that account number in the
                'Agency Account Number' field. Click submit.
              </p>

              <div class="d-flex mb-6">
                <v-text-field
                  label="Agency Account Number"
                  v-model="form.agencyCode"
                  @update:model-value="quickCheck"
                  class="flex-1"
                  :disabled="check?.status === 'ok'"
                ></v-text-field>
              </div>

              <v-alert
                type="warning"
                prominent
                border="top"
                color="red-darken-3"
                class="mb-6"
                v-if="check?.status === 'error'"
                >{{ check.data }}
              </v-alert>

              <v-btn
                class="mb-4"
                color="primary"
                :disabled="!form.agencyCode"
                v-if="check?.status !== 'ok'"
                :loading="loading"
                @click="handleAgencyCheck"
                template
                >Submit</v-btn
              >
            </template>

            <AppLoading v-if="form.step === '3' && form.agencyMagicCode.length > 0 && loading" />

            <template v-if="check?.status === 'ok' && check.data.isuserconfigured === false">
              <p class="mt-4 mb-4">Now, let's collect some additional information about you.</p>
              <v-text-field v-model="form.firstname" label="First Name"></v-text-field>
              <v-text-field v-model="form.lastname" label="Last Name"></v-text-field>
              <v-text-field v-model="form.title" label="Title"></v-text-field>
            </template>

            <template v-if="check?.status === 'ok' && check.data.isstudyincluded === false">
              <p class="mt-4 mb-4">
                We need a bit more information. You can use the selection box below to select the study you work with.
              </p>

              <ModelSelect
                class="mb-4"
                v-model="check.city"
                placeholder="Select your city..."
                :options="cities.map((item: any) => ({ value: item, text: item }))"
              />

              <ModelSelect
                class="mb-4"
                :isDisabled="!check.city"
                v-model="check.location"
                placeholder="Select your location..."
                :options="locations.map((item: any) => ({ value: item.locationid, text: item.name }))"
              />
            </template>

            <template v-if="check?.status === 'ok'">
              <v-alert type="info">
                <p>
                  Authorized Organization User: Licensed access to this site is for the exclusive use of this
                  Organization and exclusively for its mission planning. Personal use or use for a business or another
                  organization other than the licensing agency is expressly forbidden. User also agrees not to share
                  his/her username or password with anyone else. Failure to abide by these terms will be a violation of
                  this license agreement and may result in the revocation of access to this user.
                </p>

                <p>
                  Organization Location Advisory The first step after completing the registration process is to login to
                  your local organization. The current location was provided by your agency and in some cases the
                  address information is incomplete and does not allow an accurate placement of your organization. (This
                  is more likely to happen for locations in rural areas.) There is no cause for alarm. It is an easy
                  process to move your organization’s geographic location on the map once we receive additional
                  information from you. Normally, this change can be made within 48 hours. Simply send an email to your
                  Agency Administrator or to MissionInsite at
                  <a class="underline text-white" :href="`mailto:${contacts.email}`"> {{ contacts.email }}</a> with
                  additional information on your organization’s location. Please note: Though your organization may be
                  temporarily in the wrong location, the demographic information is accurate for the area. Individual
                  organization locations are an added overlay and do not influence how the underlying demographic data
                  is stored and reported.
                </p>
              </v-alert>

              <v-checkbox label="Accept Terms" v-model="form.acceptTerms" />

              <v-btn
                class="mt-4"
                color="primary"
                :disabled="disabled"
                :loading="check?.status === 'ok' && loading"
                @click="handleRegister"
                >Register</v-btn
              >
            </template>
          </div>

          <div v-if="form.step === '5'">
            <h3 class="text-h6 mb-4">Registration Complete</h3>

            <p>
              Thank you for registering, {{ form.name }}! An email has been sent to the Agency Administrator requesting
              that they activate your account.
            </p>

            <p>
              <small>
                You will receive an email once your account has been activated and you are able to use MissionInsite.
                Until that time, you may continue to login to the application and see the current status of your
                account, or access alternate Agencies normally if you are registered with multiple Agencies.
              </small>
            </p>

            <p><small> Thank you for signing up for MissionInsite! </small></p>

            <p>
              <a taret="_blank" class="underline" :href="publicUrls.login">Go To Application</a>
            </p>
          </div>
        </div>
      </div>
    </template>
  </LayoutWithCopyright>
</template>

<script setup lang="ts">
import auth from '@/services/auth.service';

const DEFAULT_DATA = {
  step: '1',
  agencyCode: '',
  agencyMagicCode: '',
  acceptTerms: false,
  firstname: '',
  lastname: '',
  title: '',
  city: '',
  location: null,
  name: '',
};
import LayoutWithCopyright from '@/components/general/LayoutWithCopyright.vue';
import { ref, watch } from 'vue';
import { contacts } from '@/constants/web';
import AppLoading from '@/components/general/Loading.vue';
const router = useRouter();
const form = useStorage('register', clone(DEFAULT_DATA), localStorage, { mergeDefaults: true });
const loading = ref(false);
const check = ref<any>(null);
const cities = computed(() => check.value?.locationsbycityList?.map((item: any) => item.city));
const locations = computed(() => {
  if (!check.value?.city) return [];
  const city = check.value?.locationsbycityList?.find((item: any) => item.city === check.value.city);
  return sortBy(city?.locationsList, location => location.name);
});

watch(locations, () => {
  if (locations.value.length) {
    check.value.location = locations.value[0]?.locationid;
  }
});

import image from '@/assets/img/mi-logo.png';
import { useStorage } from '@vueuse/core';
import { useRouter } from 'vue-router';
import { onMounted } from 'vue';
import { clone } from '@/utils/common';
import { publicUrls } from '@/constants/urls';
import {
  verifyRegistrationCode,
  applyRegistrationCode,
  userProfileUpdate,
  getUserFromToken,
} from '@/services/api.service';
import { computed } from 'vue';
import { notify } from '@kyvg/vue3-notification';
import { locationByCity } from '@/services/api.service';
import { ModelSelect } from 'vue-search-select';
import { sortBy } from 'lodash';

const disabled = computed(() => {
  if (!form.value.acceptTerms) return true;
  if (check.value?.status !== 'ok') return true;
  if (!check.value.location) return true;
  return false;
});

const agencyCheck = (code: string) => {
  loading.value = true;
  return verifyRegistrationCode(code)
    .then(async data => {
      const res = await locationByCity(data.agencyuuid);
      check.value = {
        status: 'ok',
        data,
        locationsbycityList: res.locationsbycityList,
      };
    })
    .catch(error => {
      console.log({ error });
      form.value = clone({ ...form.value, agencyMagicCode: '' });
      check.value = {
        status: 'error',
        data: 'Invalid registration code',
      };
    })
    .finally(() => {
      loading.value = false;
    });
};
const handleAgencyCheck = () => {
  agencyCheck(form.value.agencyCode);
};

const handleRegister = async () => {
  try {
    const userdata = await getUserFromToken();
    let name = `${userdata.firstname} ${userdata.lastname}`;
    await applyRegistrationCode(form.value.agencyCode, check.value.location);
    if (check.value?.data.isuserconfigured === false) {
      await userProfileUpdate({
        firstname: form.value.firstname,
        lastname: form.value.lastname,
        title: form.value.title,
        showwelcomescreen: true,
      });
      name = `${form.value.firstname} ${form.value.lastname}`;
    }
    return (form.value = clone({ ...form.value, name, step: '5' }));
  } catch (error) {
    console.log(error);
    notify({
      type: 'error',
      text: 'Something went wrong',
    });
  }
};

const quickCheck = () => {
  if (check.value?.status === 'error' && form.value.agencyCode.length) return (check.value = null);
};

onMounted(async () => {
  if (router.currentRoute.value.query?.setuser) {
    form.value = clone({ ...DEFAULT_DATA, step: '3' });
  }
  if (router.currentRoute.value.query?.agencyCode) {
    auth.logout(false);
    const agencyMagicCode = String(router.currentRoute.value.query?.agencyCode);
    form.value = clone({ ...DEFAULT_DATA, agencyMagicCode, agencyCode: agencyMagicCode, step: '3' });
    return (window.location.href = publicUrls.preRegistration);
  } else {
    form.value = clone({ ...DEFAULT_DATA, ...form.value, step: '3' });
  }

  if (form.value.step === '3' && form.value.agencyMagicCode.length > 0) {
    agencyCheck(form.value.agencyMagicCode);
  }
});
</script>

<style scoped>
a {
  color: #0f4689;
}
.container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
}

.loader-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dce3e6;
}
</style>
