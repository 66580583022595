import * as acst from '@acst/missioninsite-apiservice/apiservice_pb';

export type LocationsDataType = acst.LocationSearchResponse.AsObject;

export type LocationsListType = LocationsDataType['locationsList'];

export type LocationType = acst.AgencyLocationTypeMap[keyof acst.AgencyLocationTypeMap];

export enum PhoneType {
  MAIN_PHONE = acst.AgencyLocationPhoneType.AGENCYLOCATIONPHONETYPE_MAIN,
  OTHER_PHONE = acst.AgencyLocationPhoneType.AGENCYLOCATIONPHONETYPEE_OTHER,
  FAX = acst.AgencyLocationPhoneType.AGENCYLOCATIONPHONETYPE_FAX,
}
