interface IProductionConfig {
  apiServiceUrl: string;
  apiMapboxToken: string;
  googleTagCode: string;
  locatorAppUrl: string;
}

const config: IProductionConfig = {
  apiServiceUrl: process.env.VUE_APP_API_SERVICE_URL || '',
  apiMapboxToken: process.env.VUE_APP_MAPBOX_TOKEN || '',
  googleTagCode: process.env.VUE_APP_GOOGLE_TAGS_CODE || '',
  locatorAppUrl: process.env.VUE_APP_LOCATOR_BASE_URL || '',
};

import { event } from 'vue-gtag';

export const initConfig = async () => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }
  try {
    const response = await fetch('/config');
    const json = await response.json();

    config.apiServiceUrl = json['VUE_APP_API_SERVICE_URL'];
    config.apiMapboxToken = json['VUE_APP_MAPBOX_TOKEN'];
    config.googleTagCode = json['VUE_APP_GOOGLE_TAGS_CODE'];
    config.locatorAppUrl = json['VUE_APP_LOCATOR_BASE_URL'];
  } catch (e: unknown) {
    event('error', { message: e });
    console.error(e);
  }
};

export default config;
