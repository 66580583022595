import auth from '@/services/auth.service';
import AuthService from '@/services/auth.service';
import { GetUserFromTokenResponse } from '@acst/missioninsite-apiservice/apiservice_pb';

import { defineStore } from 'pinia';

export type Permission =
  | 'iajm'
  | 'iasm'
  | 'iasv'
  | 'mapu'
  | 'mapp'
  | 'maum'
  | 'asa'
  | 'iaav'
  | 'masa'
  | 'iajv'
  | 'idtm'
  | 'aaa'
  | 'maue'
  | 'male'
  | 'maam'
  | 'iabm'
  | 'iabv'
  | 'iaam'
  | 'mala'
  | 'matm'
  | 'maui'
  | 'manc'
  | 'marr'
  | 'madu'
  | 'madm'
  | 'malm'
  | 'maos'
  | 'mafa'
  | 'maal'
  | 'iauv'
  | 'iaum'
  | 'madr'
  | 'maar'
  | 'manr';
export type Permissions = Permission[];

export type User = GetUserFromTokenResponse.AsObject;

export interface AuthState {
  user: User | null;
  pending: boolean;
  finished: boolean;

  studyTokenPending: boolean;
  isStudyInToken: boolean;
}

const useAuthStore = defineStore({
  id: 'auth',
  state: () =>
    ({
      user: null,
      pending: false,
      finished: false,
      studyTokenPending: false,
      isStudyInToken: false,
    }) as AuthState,
  getters: {},
  actions: {
    async getCurrentUser() {
      this.pending = true;
      this.finished = false;
      const user = await AuthService.getCurrentUser();
      this.finished = true;
      this.user = user;
      this.pending = false;
    },

    setUser(user: User | null) {
      this.user = user;
      this.finished = true;
      this.pending = false;
    },

    async logout(): Promise<void> {
      await auth.logout();
    },

    hasPermission(acl: Permission): boolean {
      return !!this.user?.aclsList?.includes(acl);
    },
  },
});

export default useAuthStore;
