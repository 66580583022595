import _castArray from 'lodash/castArray';

import { User, Permissions } from '@/stores/auth';
import { isEmpty } from 'lodash';

export const userHasPermission = ({
  user,
  requiredAcl,
  requiredAnyAcl,
}: {
  user: User | null;
  requiredAcl?: Permissions;
  requiredAnyAcl?: Permissions;
}) => {
  if (!user) {
    return false;
  }
  const checkRequiredAcl = (acls?: Array<string>, requiredAcl?: Permissions): boolean => {
    if (!acls) {
      return false;
    }

    return _castArray(requiredAcl).some(acl => {
      if (!acls.includes(acl)) {
        return false;
      }

      return true;
    });
  };

  const checkPossibleAcl = (acls: Array<string>, possibleAcl?: Permissions): boolean => {
    if (!acls) {
      return false;
    }
    return _castArray(possibleAcl).some(role => {
      if (acls.includes(role)) {
        return true;
      }
      return false;
    });
  };

  if (isEmpty(requiredAcl) && isEmpty(requiredAnyAcl)) {
    return true;
  }
  if (isEmpty(requiredAcl) && !isEmpty(requiredAnyAcl)) {
    return checkPossibleAcl(user?.aclsList, requiredAnyAcl);
  }
  return checkRequiredAcl(user?.aclsList, requiredAcl);
};
