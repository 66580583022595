import { geojsonToGeosGeom, geosGeomToGeojson } from 'geos-wasm/helpers';

import getGeosInstance from '@/config/geos';

import { customShapesFix } from '@/services/api.service';
import { parseWkt, stringifyWkt } from './common';

export function makeValidGeos(feature: GeoJSON.Feature): GeoJSON.Feature {
  const geosInstance = getGeosInstance();
  if (geosInstance === null) return feature;

  const geosGeometry = geojsonToGeosGeom(feature, geosInstance);
  const validatedGeosGeometry = geosInstance.GEOSMakeValid(geosGeometry);

  const geometry = geosGeomToGeojson(validatedGeosGeometry, geosInstance);
  return { ...feature, geometry };
}

export function isValidGeos(feature: GeoJSON.Feature): boolean {
  const geosInstance = getGeosInstance();
  if (geosInstance === null) return true;

  const geosGeometry = geojsonToGeosGeom(feature, geosInstance);
  const validityCode = geosInstance.GEOSisValid(geosGeometry);

  return validityCode === 1 ? true : false;
}

export async function isValidByPostGIS(
  feature: GeoJSON.Feature,
): Promise<{ geometry: GeoJSON.Geometry | null; valid: boolean }> {
  const response = await customShapesFix(stringifyWkt(feature.geometry));
  const geometry = parseWkt(response.geom);

  return { geometry, valid: response.isFixed };
}
