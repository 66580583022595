import { isArray, castArray, cloneDeep, flowRight } from 'lodash';
import {
  featureCollection,
  feature,
  union,
  difference,
  transformRotate,
  transformScale,
  polygon,
  buffer,
  simplify,
  point,
  pointsWithinPolygon,
  type FeatureCollection,
  type Feature,
  type Point,
  type Polygon,
  type MultiPolygon,
  type Position,
} from '@turf/turf';

import type { IAdvancedShapeSettings } from '@/types/shape';

export const getFeatureCoordinates = (feature: GeoJSON.Feature): Position[] => {
  switch (feature.geometry.type) {
    case 'Polygon':
      return feature.geometry.coordinates[0];
    case 'MultiPolygon':
      return feature.geometry.coordinates.map(coord => coord[0]).reduce((prev, curr) => prev.concat(curr), []);
    case 'LineString':
      return feature.geometry.coordinates;
    case 'MultiLineString':
      return feature.geometry.coordinates.reduce((prev, curr) => prev.concat(curr), []);
    case 'Point':
      return [feature.geometry.coordinates];
    case 'MultiPoint':
      return feature.geometry.coordinates;
    default:
      return [];
  }
};

export const unionFeatures = (
  feature1: Feature<Polygon | MultiPolygon>,
  feature2: Feature<Polygon | MultiPolygon>,
): Feature<Polygon | MultiPolygon> | null => {
  return union(feature1, feature2);
};

export const getPointsWithinPolygon = (
  vertices: Feature<Point>[],
  selection: Feature<Polygon>,
): FeatureCollection<Point> => {
  const pointsFeatureCollection = featureCollection(
    vertices.map(vertice => point(vertice.geometry.coordinates, vertice.properties)),
  );

  return pointsWithinPolygon(pointsFeatureCollection, selection);
};

export const getMaskedPolygonFeature = (
  selectionMask: Feature<Polygon | MultiPolygon>,
  shape: Feature<Polygon | MultiPolygon>,
): Feature<Polygon | MultiPolygon> | null => {
  const shapeClone = cloneDeep(shape);

  return difference(shapeClone, selectionMask);
};

const parseGeometryCollection = (geojson: GeoJSON.GeoJSON): GeoJSON.GeoJSON[] | GeoJSON.GeoJSON => {
  if (geojson.type === 'GeometryCollection') {
    const parsedCollection = geojson.geometries.reduce((accum, geometry) => {
      const parsedGeometry = parseGeometryCollection(geometry);

      return isArray(parsedGeometry) ? [...accum, ...parsedGeometry] : [...accum, parsedGeometry];
    }, [] as GeoJSON.GeoJSON[]);

    return parsedCollection;
  }

  return geojson;
};

export const convertToFeatureCollection = (collection: GeoJSON.GeoJSON): GeoJSON.FeatureCollection => {
  const parsedGeojson = parseGeometryCollection(collection);

  if (!isArray(parsedGeojson) && parsedGeojson.type === 'FeatureCollection') {
    return parsedGeojson;
  }

  const features = castArray(parsedGeojson);
  return featureCollection(features.map(f => (f.type === 'Feature' ? f : feature(f)))) as GeoJSON.FeatureCollection;
};

interface IShapePropsTransfrom {
  shape: Feature;
  settings: IAdvancedShapeSettings;
}

const rotateShape = ({ shape, settings }: IShapePropsTransfrom): IShapePropsTransfrom => {
  return { shape: transformRotate(shape, settings.rotate), settings };
};

const scaleShape = ({ shape, settings }: IShapePropsTransfrom): IShapePropsTransfrom => {
  const factor = settings.scale / 100;
  return { shape: factor === 0 ? shape : transformScale(shape, factor), settings };
};

const bufferShape = ({ shape, settings }: IShapePropsTransfrom): IShapePropsTransfrom => {
  return {
    shape: polygon(buffer(shape, settings.buffer).geometry.coordinates as Position[][], shape.properties, {
      id: shape.id,
    }),
    settings,
  };
};

const simplifyShape = ({ shape, settings }: IShapePropsTransfrom): IShapePropsTransfrom => {
  const tolerance = settings.simplify / 100000;
  return {
    shape: tolerance === 0 ? shape : simplify(shape, { tolerance: tolerance, highQuality: true }),
    settings,
  };
};

export const applyShapeTransfom = ({ shape, settings }: IShapePropsTransfrom): GeoJSON.Feature => {
  const composeFn = flowRight(rotateShape, scaleShape, bufferShape, simplifyShape);
  const transformedShape = composeFn({ shape, settings }).shape;

  return transformedShape as GeoJSON.Feature;
};
