import { computed, ref } from 'vue';
import { useImmer } from '@/hooks/useImmer';

import type { IHistoryAnnotation } from '@/types/shape';

export function useHistory<T, A extends IHistoryAnnotation>(baseState: T) {
  const historyIndex = ref(0);
  const [annotations, updateAnnotations] = useImmer<A[]>([]);
  const [history, updateHistory] = useImmer<T[]>([baseState]);

  const current = computed(() => {
    return history.value[historyIndex.value];
  });

  const hasUndo = computed(() => {
    return historyIndex.value !== 0;
  });

  const hasRedo = computed(() => {
    return historyIndex.value !== history.value.length - 1;
  });

  function redoOperation() {
    if (historyIndex.value < history.value.length) historyIndex.value++;
  }

  function undoOperation() {
    if (historyIndex.value > 0) historyIndex.value--;
  }

  function historyOperation(fn: (history: T) => T, annotation: A) {
    updateHistory(draft => draft.slice(0, historyIndex.value + 1));
    updateAnnotations(draft => draft.slice(0, historyIndex.value + 1));

    const newVersion = fn(history.value[historyIndex.value]);
    const lastAnnotation = annotations.value[annotations.value.length - 1];

    if (annotation.override || (annotation.replaceable && lastAnnotation?.type === annotation.type)) {
      updateHistory(draft => [...draft.slice(0, -1), newVersion]);
      updateAnnotations(draft => [...draft.slice(0, -1), annotation]);
      return;
    }

    updateHistory(draft => [...draft, newVersion]);
    updateAnnotations(draft => [...draft, annotation]);
    historyIndex.value++;
  }

  function historyOverride(fn: (history: T) => T) {
    updateHistory(draft => draft.map(snapshot => fn(snapshot)));
  }

  function clearHistory() {
    historyIndex.value = 0;

    updateHistory(() => [[]]);
    updateAnnotations(() => []);
  }

  return { current, hasUndo, hasRedo, historyOperation, historyOverride, undoOperation, redoOperation, clearHistory };
}
