import { h } from 'vue';
import type { IconSet, IconProps } from 'vuetify';

const di: IconSet = {
  component: (props: IconProps) => {
    return h('i', { class: props.icon });
  },
};

export { di };
