import { defineStore } from 'pinia';

export type TaskItem = {
  name: string;
  identifier: string;
  untrackable?: boolean;
  canCancel?: boolean;
};

const coreTask: TaskItem = { name: 'System Idle...', identifier: 'idle' };

type TasksState = {
  tasks: TaskItem[];
  reports: Array<string>;
  completePercent: number;
};

const useTasksStore = defineStore({
  id: 'status',
  state: () =>
    ({
      tasks: [],
      reports: [],
      completePercent: 0,
    }) as TasksState,
  getters: {
    isRunning: state => {
      return state.tasks.length > 0;
    },
    currentTask: state => {
      return state.tasks.length === 0 ? coreTask : state.tasks[0];
    },
  },

  actions: {
    startTask({ name, identifier, untrackable = true, canCancel = true }: TaskItem) {
      if (!this.tasks.find(item => item.identifier === identifier)) {
        const newItem = { name, identifier, untrackable, canCancel };
        this.tasks = [...this.tasks, newItem];
      }
    },
    cancelTask(identifier: string) {
      const found = this.tasks.find(item => item.identifier !== coreTask.identifier);
      if (!found || found.canCancel === false) return;

      this.completeTask(identifier);
    },
    completeTask(identifier: string) {
      this.tasks = this.tasks.filter(item => item.identifier !== identifier);
    },
    percent(complete: number) {
      this.completePercent = complete;
    },
    updateIdentifier(identifier: string, newIdentifier: string) {
      this.tasks = this.tasks.map(item => {
        const updated = item.identifier === identifier ? newIdentifier : item.identifier;
        return { ...item, identifier: updated };
      });
    },
  },
});

export default useTasksStore;
