import tinyColor from 'tinycolor2';
import mapboxgl from 'mapbox-gl';

import type { IPolygonStyles } from '@/types/agency';
import type { IMapboxStyle } from '@/types/map';

export const getEmbedStyles = (showLabels = false, visible: boolean): IMapboxStyle => {
  return {
    polygon: {
      paint: {
        'fill-color': ['get', 'fillColor'],
        'fill-opacity': ['*', ['get', 'fillOpacity'], 0.01],
        'fill-outline-color': ['get', 'strokeColor'],
      },
      layout: {},
    },
    line: {
      paint: {
        'line-color': ['get', 'strokeColor'],
        'line-width': ['get', 'strokeWidth'],
      },
      layout: {},
    },
    symbolLabel: {
      layout: {
        'text-field': ['get', 'name'],
        'text-size': 14,
        'text-offset': [0, 0],
        visibility: visible && showLabels ? 'visible' : 'none',
      },
      paint: {
        'text-halo-color': '#fff',
        'text-halo-width': 2,
        'text-halo-blur': 0.8,
      },
    },
  };
};

export const getPolygonStyles = (styles: IPolygonStyles, showLabels = false, visible = false): IMapboxStyle => {
  return {
    polygon: {
      paint: {
        'fill-color': tinyColor(styles.fillcolor).toRgbString(),
        'fill-opacity': styles.fillopacity,
        'fill-outline-color': tinyColor(styles.fillcolor).setAlpha(1).toRgbString(),
      },
      layout: {},
    },
    line: {
      paint: {
        'line-color': tinyColor(styles.strokecolor).setAlpha(1).toRgbString(),
        'line-width': styles.strokewidth || 2,
      },
      layout: {},
    },
    symbolLabel: {
      layout: {
        'text-field': ['get', 'name'],
        'text-size': 14,
        'text-offset': [0, 0],
        visibility: visible && showLabels ? 'visible' : 'none',
      },
      paint: {
        'text-halo-color': '#fff',
        'text-halo-width': 2,
        'text-halo-blur': 0.8,
      },
    },
  };
};

export const getPointStyles = (
  showLabels = true,
  originalStyles: Record<string, any> | undefined,
  visible: boolean,
): IMapboxStyle => {
  const icon = originalStyles?.icon as string | 'Church';
  const circleColor = icon === 'Church' ? '#0525FE' : '#C204BF';

  return {
    point: {
      layout: {},
      paint: {
        'circle-radius': {
          stops: [
            [0, 2.5],
            [7, 2.5],
            [8, 0],
          ],
        },
        'circle-color': circleColor,
        'circle-stroke-width': 1,
        'circle-stroke-color': '#000000',
      },
    },

    symbol: {
      layout: {
        'icon-image': icon,
        'icon-size': {
          stops: [
            [0, 0],
            [7, 0],
            [8, 1],
          ],
        },
        'icon-allow-overlap': true,
        'text-allow-overlap': true,
      },
      paint: {},
    },
    symbolLabel: {
      layout: {
        'text-field': ['get', 'name'],
        'text-size': 12,
        'text-offset': [0, 2],
        visibility: visible && showLabels ? 'visible' : 'none',
      },
      paint: {
        'text-halo-color': '#fff',
        'text-halo-width': 2,
        'text-halo-blur': 0.8,
      },
    },
  };
};

export const polygonSelectionPaint = (): mapboxgl.FillPaint => {
  return {
    'fill-color': 'rgba(0, 51, 204, .3)',
    'fill-outline-color': 'rgba(0, 51, 204, 1)',
  };
};

export const polygonSelectionOutlinePaint = (): mapboxgl.LinePaint => {
  return {
    'line-color': 'rgba(0, 51, 204, 1)',
    'line-width': 3,
  };
};

export const selectionLabelPaint = (): mapboxgl.SymbolPaint => {
  return {
    'text-halo-color': '#fff',
    'text-halo-width': 2,
    'text-halo-blur': 0.8,
  };
};

export const selectionLabelLayout = (defaultLabel = 'name'): mapboxgl.SymbolLayout => {
  return {
    'text-field': `{${defaultLabel}}`,
    'text-size': {
      stops: [
        [0, 0],
        [6, 10],
        [12, 24],
      ],
    },
    'text-offset': [0, 0],
  };
};
