import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { Feature, LineString, Polygon } from '@turf/turf';
import mapboxgl from 'mapbox-gl';

export type TMap = mapboxgl.Map;
export type TMarker = mapboxgl.Marker;
export type TMapOptions = mapboxgl.MapboxOptions;

export type TLayerLayout = mapboxgl.FillLayout | mapboxgl.CircleLayout | mapboxgl.SymbolLayout | mapboxgl.LineLayout;
export type TMapLayer = mapboxgl.FillLayer | mapboxgl.CircleLayer | mapboxgl.SymbolLayer | mapboxgl.LineLayer;
export type TMapFilter = unknown[] | boolean | null;

export type TControlPosition = 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left';

export type TCoord = [number, number] | { lat: number; lng: number };
export type TPointLike = mapboxgl.PointLike;
export type TLngLatLike = mapboxgl.LngLatLike;
export type TLngLat = { lng: number; lat: number };

export type TMapboxEvent = mapboxgl.MapboxEvent;
export type TMapEvent = mapboxgl.MapEventType;
export type TMapDrawEvent = MapboxDraw.DrawEventType | 'draw.select-points' | 'draw.carve-out';

export type TMapLayerEventType = mapboxgl.MapLayerEventType;
export type TMapListenerEvent = (mapboxgl.MapLayerMouseEvent | mapboxgl.MapLayerTouchEvent) & mapboxgl.EventData;
export type TMapListener = (event: TMapListenerEvent) => void;

export type TMapLayerListeners = Partial<Record<keyof TMapLayerEventType, TMapListener>>;

interface IGeocodeFeature {
  id: string;
  type: string;
  place_type: string[];
  relevance: number;
  address?: string;
  text: string;
  place_name: string;
  matching_text: string;
  matching_place_name: string;
  language: string;
  bbox?: number[];
  center: number[];
  geometry: GeoJSON.Geometry;
  context: IGeocodeFeature[];
}
interface ILayerProperties {
  color: string;
  contour: number;
  fill: string;
  'fill-opacity': number;
  fillColor: string;
  fillOpacity: number;
  metric: string;
  opacity: number;
}
export interface IIsochroneFeature {
  type: string;
  properties: ILayerProperties;
  geometry: {
    type: 'Polygon';
    coordinates: any[];
  };
}

export interface IGeocodingResult {
  type: string;
  query: string[];
  features: IGeocodeFeature[];
  attribution: string;
}
export interface IIsochroneResult {
  type: string;
  features: IIsochroneFeature[];
}

export interface INavigationControlOptions {
  showCompass?: boolean | undefined;
  showZoom?: boolean | undefined;
  visualizePitch?: boolean | undefined;
}

export interface IMapboxStyle {
  polygon?: IPolygonStyle;
  line?: ILineStyle;
  symbol?: ISymbolStyle;
  symbolLabel?: ISymbolLabelStyle;
  point?: IPointStyle;
  data?: IStyleData;
}

export interface ILineStyle {
  paint: mapboxgl.LinePaint;
  layout: mapboxgl.LineLayout;
}

export interface IPolygonStyle {
  paint: mapboxgl.FillPaint;
  layout: mapboxgl.FillLayout;
}

export interface IPointStyle {
  paint: mapboxgl.CirclePaint;
  layout: mapboxgl.CircleLayout;
}

export interface ISymbolStyle {
  layout: mapboxgl.SymbolLayout | mapboxgl.CircleLayout;
  paint: mapboxgl.SymbolPaint | mapboxgl.CirclePaint;
}

export interface ISymbolLabelStyle {
  layout: mapboxgl.SymbolLayout;
  paint: mapboxgl.SymbolPaint;
}

export interface IStyleData {
  uuid: string;
}

export type ISource =
  | {
      id: string;
      type: 'geojson';
      layer: string;
      data: string;
      metadata: { agencyUuid: string; layer: string };
      loaded: boolean;
    }
  | {
      id: string;
      type: 'vector';
      url: string;
      layer: string;
    };

export enum LayerIdPrefixes {
  // Add "mi" layer prefix to every custom layer and source (thematic, plotting, etc.) except shapes
  // Layers with that prefix will be added after using map.setStyle()
  mi = 'missioninsite',

  base = 'base',
  helper = 'helper',
  selection = 'selection',
  thematic = 'thematic',
  plotting = 'plotting',
  neighbors = 'neighbors',
}

export enum LayerTypePrefix {
  fill = 'fill',
  symbol = 'symbol',
  line = 'line',
  circle = 'circle',
  heatmap = 'heatmap',
}

export enum NeighborLayerPrefix {
  all = 'all',
  clustered = 'clustered',
  nonClustered = 'nonClustered',
  highZoomCluster = 'highZoomCluster',
  heatmap = 'heatmap',
  circle = 'circle',
}

export interface IPreparedLayer {
  id: string;
  name: string;
  baseLayerId: string;
  layers: TMapLayer[];
  source: ISource;
  listeners?: { eventListeners: TMapLayerListeners; layerId: string };
  beforeLayerId?: string;
}

export interface IFullScreenControl {
  container: HTMLElement | null | undefined;
}

export type TGeometries =
  | GeoJSON.Point
  | GeoJSON.LineString
  | GeoJSON.Polygon
  | GeoJSON.MultiPoint
  | GeoJSON.MultiLineString
  | GeoJSON.MultiPolygon;

export enum MapInteractionMode {
  selectSingle = 'selectSingle',
  selectMultiple = 'selectMultiple',
  showCoordinates = 'showCoordinates',
}

export interface GeocodeAddressResult {
  title: string;
  value: { title: string; geometry: GeoJSON.Geometry };
}

export interface BoundaryProperties {
  name: string;
  showLabel: boolean;
  changed: boolean;
  muted: boolean;

  fillcolor: string;
  fillopacity: number;

  strokecolor: string;
  strokeopacity: number;
  strokewidth: number;
}
export interface BoundaryFeature {
  id: number;
  properties: BoundaryProperties;
  parts: Array<Feature<Polygon | LineString>>;
}
