<template>
  <AuthLayout>
    <div class="main-screen">
      <div class="bg-primary d-flex items-center px-2 pt-2 pb-2">
        <img :src="logo" alt="" style="max-width: 360px; width: 100%" />
      </div>
      <div class="grid grid-cols-2 login-content">
        <div class="login-left text-wrap d-flex align-center flex-column gap-4">
          <v-btn color="primary" class="login-button" @click="logIn()">Log in</v-btn>
          <v-btn color="primary" class="login-button" @click="registerIn()">Registration</v-btn>
        </div>

        <div class="d-flex flex-column gap-4 text-wrap">
          <div class="text-h5 font-weight-bold">Engagement Begins Here</div>
          <p>MissionInsite helps you bridge the gap from data to decision.</p>

          <p>
            With your faith-based nonprofit’s data and current demographics information, you can understand your
            community and the people you serve.
          </p>

          <p><strong>Log in and begin exploring, interacting and learning.</strong></p>

          <p>
            Need Help with Registration? Email: <a :href="`mailto:${contacts.email}`"> {{ contacts.email }}</a> or call
            <a :href="`tel:${phone(contacts.phone.main)}`">{{ contacts.phone.main }}</a
            >.
          </p>
        </div>
      </div>
    </div>
  </AuthLayout>
</template>

<script setup lang="ts">
import { contacts } from '@/constants/web';
import logo from '@/assets/img/mi-logo.png';
import { phone } from '@/utils/common';
import { publicUrls } from '@/constants/urls';
import useGlobalStore from '@/stores/global';
import AuthLayout from './AuthLayout.vue';

const store = useGlobalStore();
store.closeDialogs();
function logIn() {
  window.location.href = publicUrls.preLogin;
}

function registerIn() {
  window.location.href = publicUrls.preRegistration;
}
</script>

<style scoped>
.login-left {
  background-color: #f2f6f8;
}
.main-screen {
  width: calc(100% - 40px);
  max-width: 1000px;
  margin: 0 auto;
  border-radius: 2px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow:
    0 1px 6px 0 rgba(0, 0, 0, 0.12),
    0 1px 6px 0 rgba(0, 0, 0, 0.12);
}
.text-wrap {
  padding: 100px 40px;
}
.login-button {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  display: inline-block;
}
a {
  transition: 0.3s;
}
a:hover {
  text-decoration: underline;
}
@media screen and (max-width: 768px) {
  .login-content {
    gap: 0;
    grid-template-columns: 1fr;
  }

  .text-wrap {
    padding: 40px 20px;
  }
  p.copy {
    display: block;
  }
  div.copy {
    display: none;
  }
}
</style>
