import { RouteLocationNormalized } from 'vue-router';
import useAuthStore from '@/stores/auth';
import useGlobalStore from '@/stores/global';
import { userHasPermission } from '@/utils/userHasPermission';
import UrlRestoringService from '@/services/urlRestoring.service';
import { publicUrls } from '@/constants/urls';
import { ProtectedRouteConfig } from '@/types/route';

const authGuard = async (to: RouteLocationNormalized /*, from: RouteLocationNormalized */) => {
  const store = useAuthStore();
  const globalStore = useGlobalStore();

  const {
    requiredAcl,
    requiredAnyAcl,
    redirectOnFail,
    saveUrlOnFail,
    redirectOnAuthorizationFail,
  }: ProtectedRouteConfig = to.meta;
  const authRequestWasSent = () => store.pending || store.finished;
  const userIsAuthenticated = () => store.finished && store.user !== null;
  const userHasAgency = () => userIsAuthenticated() && store.user?.agencyuuid;

  // const userIsConfigured = () => store.user?.agencyuuid;
  const userIsAuthorized = () => userHasPermission({ user: store.user, requiredAcl, requiredAnyAcl });
  // const userHasAcls = () => Array.isArray(store.user?.aclsList) && store.user.aclsList.length > 0;
  // const userCanSeeStudy = () => userHasAcls() && store.user?.aclsList.includes('masa');

  const ensureAuthRequestWasSent = async () => {
    if (!authRequestWasSent()) {
      if (redirectOnFail) {
        UrlRestoringService.setRedirectUrl(redirectOnFail);
      }
      await store.getCurrentUser();
      const studyUuid = to.params.study as string;

      if (store.user && studyUuid) {
        await globalStore.selectStudy(studyUuid);
      }
    }
  };

  await ensureAuthRequestWasSent();

  const renderAuthFailure = () => {
    if (saveUrlOnFail) {
      UrlRestoringService.setUrl(window.location.pathname);
    }

    if (redirectOnFail) {
      return redirectOnFail;
    }

    return publicUrls.login;
  };

  if (!userIsAuthenticated()) {
    return renderAuthFailure();
  }

  if (!userHasAgency()) {
    return;
  }

  // if (!userIsConfigured()) {
  //   return publicUrls.noagency;
  // }

  // if (!userHasAcls() || !userCanSeeStudy()) {
  //   return publicUrls.noacl;
  // }

  if (!userIsAuthorized() && redirectOnAuthorizationFail) {
    return redirectOnAuthorizationFail;
  }

  if (!userIsAuthorized()) {
    return renderAuthFailure();
  }

  return;
};
export default authGuard;
