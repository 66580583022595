import initGeosJs from 'geos-wasm';

let geosInstance: null | geos = null;

export async function initGeos() {
  if (geosInstance !== null) return;

  try {
    geosInstance = await initGeosJs();
  } catch (error) {
    console.error(error);
  }
}

export default function getGeosInstance(): null | geos {
  return geosInstance;
}
